<template>
    <div class="app-container">
        <el-row :gutter="8" class="rowBox">
            <el-col :span="4" class="app-container-userInfo1 colBox">
                <!-- <el-avatar :size="100" :src="userInfo.avatarUrl ? userInfo.avatarUrl : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
                <div class="userName">{{ userInfo.userName }}</div>
                <div v-if="userInfo.userType == 2" class="marginTop-8 marginBottom-8">{{ state.idCardState[userauthenInfo.idCardState] }}</div>
                <el-button v-if="userInfo.userType == 2 && (!userauthenInfo.idCardState || userauthenInfo.idCardState == 2)" type="primary" @click="submitCertification">{{ !userauthenInfo.idCardState ? "立即认证" : userauthenInfo.idCardState == 2 ? "重新认证" : "" }}</el-button>
                <el-button v-if="userInfo.userType == 2" type="primary" @click="withdraw">立即提现</el-button>
                <div v-if="userInfo.userType == 2 && userauthenInfo.idCardState == 2" class="reviewRemark marginTop-8">拒绝原因：{{ userauthenInfo.reviewRemark }}</div> -->
                <el-row class="userInfoRow">
                    <div class="userInfoRow-left">
                        <el-avatar :size="50" :src="userInfo.avatarUrl ? userInfo.avatarUrl : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" />
                    </div>
                    <div class="userInfoRow-center">
                        <div class="userName">{{ userInfo.userName || "--" }}</div>
                        <div v-if="userInfo.userType == 2" class="marginTop-8">{{ state.idCardState[userauthenInfo.idCardState] }}</div>
                    </div>
                    <div class="userInfoRow-right">
                        <el-button size="mini" v-if="userInfo.userType == 2 && (!userauthenInfo.idCardState || userauthenInfo.idCardState == 2)" type="primary" @click="submitCertification">{{ !userauthenInfo.idCardState ? "立即认证" : userauthenInfo.idCardState == 2 ? "重新认证" : "" }}</el-button>
                    </div>
                </el-row>
                <div v-if="userInfo.userType == 2 && userauthenInfo.idCardState == 2" class="reviewRemark marginTop-8">拒绝原因：{{ userauthenInfo.reviewRemark }}</div>
                <el-row class="acctBalanceInfo" v-if="userInfo.userType == 2">
                    <div class="marginBottom-8">
                        <span>可用金额：</span><span class="price">{{ acctBalanceInfo.amount / 1000 }}</span>
                    </div>
                    <div class="marginBottom-8">
                        <span>在途金额：</span><span class="price">{{ acctBalanceInfo.cashAmount / 1000 }}</span>
                    </div>
                    <div>
                        <span>冻结金额：</span><span class="price">{{ acctBalanceInfo.freezeAmount / 1000 }}</span>
                    </div>
                </el-row>
                <el-row class="marginTop-32">
                    <el-button :disabled="acctBalanceInfo.amount == 0" v-if="userInfo.userType == 2" type="primary" @click="withdraw">立即提现</el-button>
                </el-row>
            </el-col>
            <el-col :span="20" class="app-container-userInfo2 colBox">
                <el-row class="rightRow">
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            账号编号
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.userCode || "--" }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            手机号
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.phone || "--" }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            账号类型
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.userType == 1 ? "区代账号" : userInfo.userType == 2 ? "采集账号" : userInfo.userType == 3 ? "平台账号" : "--" }}
                        </div>
                    </el-col>

                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            注册地
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.province + userInfo.city + userInfo.district + (userInfo.address || "") }}
                        </div>
                    </el-col>
                </el-row>
                <el-row class="rightRow">
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            上级编号
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.belongToCode || "--" }}
                        </div>
                    </el-col>

                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            账号来源
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ userInfo.source == 1 ? "自注册" : userInfo.source == 2 ? "直属下级" : "--" }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            过期时间
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ moment(userInfo.expirationTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            注册时间
                        </div>
                        <div class="rightRow-title info">
                            <span class="longString"></span>
                            {{ moment(userInfo.addTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </div>
                    </el-col>
                </el-row>
                <el-row class="rightRow" v-if="userInfo.userType == 2">
                    <el-col :span="6">
                        <div class="rightRow-title">
                            <span class="longString blue"></span>
                            劳务合同
                        </div>
                        <div class="rightRow-title info">
                            <el-button type="text" @click="showContracts(userInfo)">查看</el-button>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <certification-modal :userCode="userInfo.userCode" :userauthenInfo="userauthenInfo" :certificationVisible="certificationVisible" @certificationClose="certificationClose" />
        <withdraw-submit :userCode="userInfo.userCode" :userPhone="userInfo.phone" :withdrawSubmitVisible="withdrawSubmitVisible" @withdrawSubmitClose="withdrawSubmitClose" />
        <Contracts ref="contracts"></Contracts>
    </div>
</template>
<script>
import { getUserauthenticationList } from "@/api/user";
import { acctbalanceQuery } from "@/api/acctcash";
import { mapGetters } from "vuex";
import state from "@/utils/state";
import moment from "moment";
import CertificationModal from "./components/CertificationModal";
import WithdrawSubmit from "./components/WithdrawSubmit";
import Contracts from "@/components/Contracts";
export default {
    components: { CertificationModal, WithdrawSubmit, Contracts },
    data() {
        return {
            acctBalanceInfo: {
                amount: 0,
                cashAmount: 0,
                freezeAmount: 0,
            },
            withdrawSubmitVisible: false,
            certificationVisible: false,
            userauthenInfo: {
                idCardState: 0,
            },
            activeName: "1",
            moment,
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
        state() {
            return state.state;
        },
    },
    created() {
        if (this.userInfo.userType == 2) {
            // 查询用户实名认证状态
            this.getUserauthenticationState();
            // 查询用户余额信息
            this.getAcctBalance();
        }
    },
    methods: {
        getAcctBalance() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            acctbalanceQuery(params)
                .then((res) => {
                    _this.acctBalanceInfo = res.data;
                })
                .catch((err) => {});
        },
        withdrawSubmitClose() {
            this.withdrawSubmitVisible = false;
            // 查询用户余额信息
            this.getAcctBalance();
        },
        withdraw() {
            if (this.userauthenInfo.idCardState == 0 || this.userauthenInfo.idCardState == 2) {
                this.$message({
                    message: "请先实名认证，再进行提现操作！",
                    type: "warning",
                });
                return;
            } else if (this.userauthenInfo.idCardState == 3) {
                this.$message({
                    message: "请等待实名认证结果，通过认证后方可提现！",
                    type: "warning",
                });
                return;
            }
            this.withdrawSubmitVisible = true;
        },
        certificationClose() {
            this.certificationVisible = false;
            // 查询用户实名认证状态
            this.getUserauthenticationState();
        },
        submitCertification() {
            this.certificationVisible = true;
        },
        getUserauthenticationState() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            const loading = _this.$loading({
                lock: true,
                text: "查询中,请稍后...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
                customClass: "fullScreenLoading",
            });
            getUserauthenticationList(params)
                .then((res) => {
                    loading.close();
                    _this.userauthenInfo = res.data.dataList[0] || { idCardState: 0 };
                })
                .catch((err) => {
                    loading.close();
                });
        },
        showContracts(row) {
            if (row.contractSignUrl) {
                this.$refs.contracts.init(row, true);
                return;
            } else {
                this.$message({
                    message: "检测到您未签署劳务合同请前往小程序抢单即可签署劳务合同！",
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
.app-container {
    .rowBox {
        background: #ffffff;
        border-radius: 8px;
        // padding: 16px;
    }
    .colBox {
        height: 540px;
    }
    &-userInfo1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 10px 0px 10px -4px #ededed;
        .acctBalanceInfo {
            border-radius: 8px;
            background: #eeeff4;
            margin-top: 32px;
            padding: 16px;
            font-size: 14px;
            div {
                width: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .price {
                    font-size: 20px;
                    color: #ff6767;
                    font-weight: 600;
                }
            }
        }
        .userInfoRow {
            display: flex;
            align-items: center;
            &-center {
                margin: 0 16px;
            }
        }
        .reviewRemark {
            color: #f5222d;
        }
        .userName {
            font-size: 20px;
            font-weight: 600;
            color: #333333;
        }
    }
    &-userInfo2 {
        .rightRow {
            height: 160px;
            padding: 16px 80px;
            &-title {
                margin: 16px 0;
                font-size: 14px;
                position: relative;
                margin-left: 20px;
                margin-bottom: 24px;
                color: #999999;
                .longString {
                    width: 3px;
                    height: 25px;
                    position: absolute;
                    top: -3px;
                    left: -20px;
                }
                .blue {
                    background-color: #2d7bf8;
                }
            }
            .info {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
            }
        }
        .rightRow:first-child,
        .rightRow:nth-child(2) {
            border-bottom: 1px solid #eeeff4;
        }
    }
    // &-container {
    //     padding: 0;
    //     background: #f6f8f9;
    //     .whiteBg {
    //         background: #fff;
    //     }
    // }
    // &-user-info {
    //     padding: 20px;
    //     text-align: center;

    //     ::v-deep {
    //         .el-avatar {
    //             img {
    //                 cursor: pointer;
    //             }
    //         }
    //     }

    //     &-full-name {
    //         margin-top: 15px;
    //         font-size: 24px;
    //         font-weight: 500;
    //         color: #262626;
    //     }

    //     &-description {
    //         margin-top: 8px;
    //     }

    //     &-follow {
    //         margin-top: 15px;
    //     }

    //     &-list {
    //         margin-top: 18px;
    //         line-height: 30px;
    //         text-align: left;
    //         list-style: none;

    //         h5 {
    //             margin: -20px 0 5px 0;
    //         }

    //         ::v-deep {
    //             .el-tag {
    //                 margin-right: 10px !important;
    //             }

    //             .el-tag + .el-tag {
    //                 margin-left: 0;
    //             }
    //         }
    //     }
    // }
    // &-item {
    //     display: flex;
    //     i {
    //         font-size: 40px;
    //     }

    //     &-content {
    //         box-sizing: border-box;
    //         flex: 1;
    //         margin-left: 20px;

    //         &-second {
    //             margin-top: 8px;
    //         }
    //     }
    // }
}
</style>
