<template>
    <el-dialog title="提现申请" :close-on-click-modal="false" :visible="withdrawSubmitVisible" @close="closeDialog">
        <el-alert :closable="false" class="marginBottom-16 alert" title="提现须知" type="warning" description="请在下方填写您的真实的个人信息并与身份证信息一致，信息出错或者不一致会导致提现失败。我们承诺您的资料只会在本平台打款时使用不会对外使用，请放心填写"> </el-alert>
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item prop="userName" label="姓名" label-width="100px">
                <el-input style="width: 80%" v-model="form.userName" autocomplete="off" clearable placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item prop="bankAcount" label="银行卡号" label-width="100px">
                <el-input style="width: 80%" v-model="form.bankAcount" autocomplete="off" clearable placeholder="请输入银行卡号"></el-input>
            </el-form-item>
            <el-form-item prop="bankName" label="开户行" label-width="100px">
                <el-input style="width: 80%" v-model="form.bankName" autocomplete="off" clearable placeholder="请输入开户行"></el-input>
            </el-form-item>
            <el-form-item prop="taskSelectedList" label="选择任务" label-width="100px">
                <el-table @selection-change="handleSelectionChange" row-key="taskId" v-loading="listLoading" element-loading-text="加载中" :data="taskList" border fit highlight-current-row>
                    <el-table-column type="selection" :reserve-selection="true" width="55"> </el-table-column>
                    <el-table-column label="任务名称" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="任务总金额（元）" align="center">
                        <template slot-scope="scope"> {{ (scope.row.itemCount * scope.row.itemPrice) / 1000 }} </template>
                    </el-table-column>
                    <el-table-column label="超时扣款比例" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.timeoutCutMoney + "%" }}
                        </template>
                    </el-table-column>
                    <el-table-column label="二次采集奖励" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.itemAgainMoney + "%" }}
                        </template>
                    </el-table-column>
                    <el-table-column label="已得金额（元）" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.finalMoney / 1000 }}
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" label="发布时间" align="center">
                        <template slot-scope="scope">
                            {{ moment(scope.row.addTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </template>
                    </el-table-column>
                    <el-table-column label="领取时间" :show-overflow-tooltip="true" align="center">
                        <template slot-scope="scope">
                            {{ moment(scope.row.receiveTime).format("YYYY-MM-DD HH:mm:ss") }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination class="marginTop-8 floatRight" layout="total, sizes, prev, pager, next" background :current-page="queryForm.pageNum + 1" :page-size="queryForm.pageSize" :total="queryForm.totalCount" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
            </el-form-item>
            <el-form-item prop="money" label="本次提现" label-width="100px">
                <span class="money">{{ form.money / 1000 }}</span> 元
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { isChina, isBankAcount } from "@/utils/validate";
import { getTaskList } from "@/api/task";
import { acctcashAdd } from "@/api/acctcash";
import moment from "moment";
export default {
    props: ["withdrawSubmitVisible", "userCode", "userPhone"],
    data() {
        const checkUserName = (rule, value, callback) => {
            if (value == "") {
                return callback(new Error("请输入姓名"));
            } else {
                if (isChina(value)) {
                    return callback();
                } else {
                    return callback(new Error("请输入中文姓名"));
                }
            }
        };
        const checkBankName = (rule, value, callback) => {
            if (value == "") {
                return callback(new Error("请输入开户行"));
            } else {
                if (isChina(value)) {
                    return callback();
                } else {
                    return callback(new Error("开户行必须为中文"));
                }
            }
        };
        const checkBankAcount = (rule, value, callback) => {
            if (value == "") {
                return callback(new Error("请输入银行卡号"));
            } else {
                if (isBankAcount(value)) {
                    return callback();
                } else {
                    return callback(new Error("请输入合法的银行卡号"));
                }
            }
        };
        return {
            taskList: [],
            queryForm: {
                pageNum: 0,
                pageSize: 10,
                totalCount: 0,
                receiveUserCode: this.userCode,
                state: 4,
                cashState: 0,
            },
            moment,
            listLoading: false,
            form: {
                money: 0,
                userName: "",
                bankAcount: "",
                bankName: "",
                taskSelectedList: [],
            },
            rules: {
                taskSelectedList: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择任务",
                    },
                ],
                bankAcount: [
                    {
                        required: true,
                        trigger: "change",
                        validator: checkBankAcount,
                    },
                ],
                bankName: [
                    {
                        required: true,
                        trigger: "change",
                        validator: checkBankName,
                    },
                ],
                userName: [
                    {
                        required: true,
                        trigger: "change",
                        validator: checkUserName,
                    },
                ],
            },
        };
    },
    watch: {
        withdrawSubmitVisible(data) {
            if (data) {
                // 查询可提现的任务
                this.getTaskList();
            }
        },
    },
    methods: {
        handleSelectionChange(val) {
            this.form.taskSelectedList = val;
            let money = 0;
            val.map((item) => {
                money += item.finalMoney;
            });
            this.form.money = money;
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getTaskList();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNum = val - 1;
            this.getTaskList();
        },
        getTaskList() {
            const _this = this;
            _this.listLoading = true;
            getTaskList(_this.queryForm)
                .then((res) => {
                    _this.taskList = res.data.dataList;
                    _this.queryForm.totalCount = res.data.totalCount;
                    _this.listLoading = false;
                })
                .catch((err) => {
                    _this.listLoading = false;
                    console.log(err);
                });
        },
        confirm() {
            const _this = this;
            this.$refs.form.validate((valid) => {
                let params = {
                    userCode: _this.userCode,
                    bankAcount: _this.form.bankAcount,
                    bankName: _this.form.bankName,
                    userName: _this.form.userName,
                    type: 1,
                    money: _this.form.money,
                    userPhone: _this.userPhone,
                };
                let taskIdList = [];
                _this.form.taskSelectedList.map((item) => {
                    taskIdList.push(item.taskId);
                });
                params.taskIdList = taskIdList;
                const loading = _this.$loading({
                    lock: true,
                    text: "提交中,请稍后...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                    customClass: "fullScreenLoading",
                });
                acctcashAdd(params)
                    .then((res) => {
                        loading.close();
                        _this.closeDialog();
                    })
                    .catch((err) => {
                        loading.close();
                        console.log(err);
                    });
            });
        },
        closeDialog() {
            this.$emit("withdrawSubmitClose");
            this.$refs.form.resetFields();
            this.taskList = [];
            this.queryForm.pageNum = 0;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-alert__title {
    font-size: 20px;
}
/deep/ .el-alert__description {
    font-size: 16px;
}
.money {
    font-size: 32px;
    color: #fa8c16;
}
</style>
