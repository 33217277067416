<template>
    <el-dialog title="实名认证" :close-on-click-modal="false" :visible="certificationVisible" @close="closeDialog">
        <el-form :model="addForm" ref="addForm" label-width="150px" :rules="rules">
            <el-form-item label="姓名" prop="userName">
                <el-input style="width: 30%" v-model="addForm.userName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item required label="身份证正面照" prop="idCardPositiveImg" ref="idCardPositiveImgUpload">
                <el-upload
                    :class="{ hide: hideUpload1 }"
                    accept="image/jpeg,image/png,image/jpg"
                    :on-remove="handleRemove1"
                    :on-success="successUpload1"
                    :file-list="fileList1"
                    :limit="1"
                    :action="resources_api_host + '/file/upload'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="onBeforeUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item required label="身份证反面照" prop="idCardReverseImg" ref="idCardReverseImgUpload">
                <el-upload
                    :class="{ hide: hideUpload2 }"
                    accept="image/jpeg,image/png,image/jpg"
                    :on-remove="handleRemove2"
                    :on-success="successUpload2"
                    :file-list="fileList2"
                    :limit="1"
                    :action="resources_api_host + '/file/upload'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="onBeforeUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item required label="手持身份证照片" prop="idCardHandImg" ref="idCardHandImgUpload">
                <el-upload
                    :class="{ hide: hideUpload3 }"
                    accept="image/jpeg,image/png,image/jpg"
                    :on-remove="handleRemove3"
                    :on-success="successUpload3"
                    :file-list="fileList3"
                    :limit="1"
                    :action="resources_api_host + '/file/upload'"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :before-upload="onBeforeUpload"
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        <Vviewer ref="viewer"></Vviewer>
    </el-dialog>
</template>
<script>
import Vviewer from "@/components/Vviewer";
import { isChina } from "@/utils/validate";
import { userauthenticationAdd, userauthenticationUpdate } from "@/api/user";
export default {
    components: { Vviewer },
    props: ["certificationVisible", "userCode", "userauthenInfo"],
    watch: {
        // certificationVisible(data) {
        //     if (data) {
        //         if (this.userauthenInfo.idCardState != 0) {
        //             this.addForm = {
        //                 userName: this.userauthenInfo.userName,
        //                 idCardPositiveImg: [
        //                     {
        //                         url: this.resources_host + this.userauthenInfo.idCardPositiveImg,
        //                         response: {
        //                             data: {
        //                                 filePath: this.userauthenInfo.idCardPositiveImg,
        //                                 coverImgUrl: this.userauthenInfo.idCardPositiveImg,
        //                             },
        //                         },
        //                     },
        //                 ],
        //                 idCardReverseImg: [
        //                     {
        //                         url: this.resources_host + this.userauthenInfo.idCardReverseImg,
        //                         response: {
        //                             data: {
        //                                 filePath: this.userauthenInfo.idCardReverseImg,
        //                                 coverImgUrl: this.userauthenInfo.idCardReverseImg,
        //                             },
        //                         },
        //                     },
        //                 ],
        //                 idCardHandImg: [
        //                     {
        //                         url: this.resources_host + this.userauthenInfo.idCardHandImg,
        //                         response: {
        //                             data: {
        //                                 filePath: this.userauthenInfo.idCardHandImg,
        //                                 coverImgUrl: this.userauthenInfo.idCardHandImg,
        //                             },
        //                         },
        //                     },
        //                 ],
        //             };
        //             this.fileList1 = [
        //                 {
        //                     url: this.resources_host + this.userauthenInfo.idCardPositiveImg,
        //                     response: {
        //                         data: {
        //                             filePath: this.userauthenInfo.idCardPositiveImg,
        //                             coverImgUrl: this.userauthenInfo.idCardPositiveImg,
        //                         },
        //                     },
        //                 },
        //             ];
        //             this.fileList2 = [
        //                 {
        //                     url: this.resources_host + this.userauthenInfo.idCardReverseImg,
        //                     response: {
        //                         data: {
        //                             filePath: this.userauthenInfo.idCardReverseImg,
        //                             coverImgUrl: this.userauthenInfo.idCardReverseImg,
        //                         },
        //                     },
        //                 },
        //             ];
        //             this.fileList3 = [
        //                 {
        //                     url: this.resources_host + this.userauthenInfo.idCardHandImg,
        //                     response: {
        //                         data: {
        //                             filePath: this.userauthenInfo.idCardHandImg,
        //                             coverImgUrl: this.userauthenInfo.idCardHandImg,
        //                         },
        //                     },
        //                 },
        //             ];
        //             this.hideUpload1 = this.fileList1.length >= 1;
        //             this.hideUpload2 = this.fileList2.length >= 1;
        //             this.hideUpload3 = this.fileList3.length >= 1;
        //         }
        //     }
        // },
    },
    data() {
        const _this = this;
        const checkIdCardPositiveImg = (rule, value, callback) => {
            if (_this.fileList1.length == 0) {
                return callback(new Error("请上传身份证正面照"));
            } else {
                callback();
            }
        };
        const checkIdCardReverseImg = (rule, value, callback) => {
            if (_this.fileList2.length == 0) {
                return callback(new Error("请上传身份证反面照"));
            } else {
                callback();
            }
        };
        const checkIdCardHandImg = (rule, value, callback) => {
            if (_this.fileList3.length == 0) {
                return callback(new Error("请上传手持身份证照片"));
            } else {
                callback();
            }
        };
        const checkUserName = (rule, value, callback) => {
            if (value == "") {
                return callback(new Error("请输入姓名"));
            } else {
                if (isChina(value)) {
                    return callback();
                } else {
                    return callback(new Error("请输入中文姓名"));
                }
            }
        };
        return {
            fileList1: [],
            fileList2: [],
            fileList3: [],
            hideUpload1: false,
            hideUpload2: false,
            hideUpload3: false,
            addForm: {
                userName: "",
                idCardPositiveImg: [],
                idCardReverseImg: [],
                idCardHandImg: [],
            },
            rules: {
                userName: [{ validator: checkUserName }],
                idCardPositiveImg: [{ validator: checkIdCardPositiveImg }],
                idCardReverseImg: [{ validator: checkIdCardReverseImg }],
                idCardHandImg: [{ validator: checkIdCardHandImg }],
            },
            fileList: [],
            resources_api_host: process.env.VUE_APP_RESOURCES_API_HOST,
            resources_host: process.env.VUE_APP_RESOURCES_HOST,
        };
    },
    methods: {
        confirm() {
            const _this = this;
            _this.$refs.addForm.validate((valid) => {
                if (valid) {
                    let params = {
                        idCardHandImg: _this.addForm.idCardHandImg[0].response.data.filePath,
                        idCardPositiveImg: _this.addForm.idCardPositiveImg[0].response.data.filePath,
                        idCardReverseImg: _this.addForm.idCardReverseImg[0].response.data.filePath,
                        userCode: _this.userCode,
                        userName: _this.addForm.userName,
                    };
                    const loading = _this.$loading({
                        lock: true,
                        text: "提交中,请稍后...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                        customClass: "fullScreenLoading",
                    });
                    userauthenticationAdd(params)
                        .then((res) => {
                            loading.close();
                            _this.closeDialog();
                        })
                        .catch((err) => {
                            loading.close();
                        });
                }
            });
        },
        onBeforeUpload(file) {
            const isIMAGE = file.type == "image/jpeg" || file.type == "image/jpg" || file.type == "image/png";
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (!isIMAGE) {
                this.$message.error("上传文件只能是图片格式（jpg/jpeg/png）!");
                return false;
            }
            if (!isLt5M) {
                this.$message.error("上传文件大小不能超过 5MB!");
                return false;
            }
            return isIMAGE && isLt5M;
        },
        handlePictureCardPreview(file) {
            const _this = this;
            this.$refs.viewer.show([
                {
                    thumbnail: _this.resources_host + file.response.data.coverImgUrl,
                    source: _this.resources_host + file.response.data.filePath,
                },
            ]);
        },
        successUpload1(res, file, fileList) {
            if (res.rescode == 200) {
                this.fileList1 = fileList;
                this.addForm.idCardPositiveImg = fileList;
                this.$refs.idCardPositiveImgUpload.clearValidate();
                this.hideUpload1 = this.fileList1.length >= 1;
            }
        },
        successUpload2(res, file, fileList) {
            if (res.rescode == 200) {
                this.fileList2 = fileList;
                this.addForm.idCardReverseImg = fileList;
                this.$refs.idCardReverseImgUpload.clearValidate();
                this.hideUpload2 = this.fileList2.length >= 1;
            }
        },
        successUpload3(res, file, fileList) {
            if (res.rescode == 200) {
                this.fileList3 = fileList;
                this.addForm.idCardHandImg = fileList;
                this.$refs.idCardHandImgUpload.clearValidate();
                this.hideUpload3 = this.fileList3.length >= 1;
            }
        },
        handleRemove1(file, fileList) {
            this.addForm.idCardPositiveImg = fileList;
            this.fileList1 = fileList;
            this.hideUpload1 = this.fileList1.length >= 1;
        },
        handleRemove2(file, fileList) {
            this.addForm.idCardReverseImg = fileList;
            this.fileList2 = fileList;
            this.hideUpload2 = this.fileList2.length >= 1;
        },
        handleRemove3(file, fileList) {
            this.addForm.idCardHandImg = fileList;
            this.fileList3 = fileList;
            this.hideUpload3 = this.fileList3.length >= 1;
        },
        closeDialog() {
            this.$refs.addForm.resetFields();
            this.fileList1 = [];
            this.fileList2 = [];
            this.fileList3 = [];
            this.addForm = {
                userName: "",
                idCardPositiveImg: [],
                idCardReverseImg: [],
                idCardHandImg: [],
            };
            this.hideUpload1 = false;
            this.hideUpload2 = false;
            this.hideUpload3 = false;
            this.$emit("certificationClose");
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .hide .el-upload--picture-card {
    display: none;
}
</style>
