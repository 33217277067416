import request from '@/utils/request'

/**
 * 查询任务列表
 * @param  data 
 * @returns 
 * data:{
    pageNum (integer, optional): 分页页码 ,
    pageSize (integer, optional): 分页每页条数 ,
    province (string, optional): 省份 ,
    city (string, optional): 市 ,
    district (string, optional): 区/县 ,
    userName (string, optional): 用户姓名 ,
    phone (string, optional): 手机号码 ,
    state (integer, optional): 用户状态：1：已生效；2：禁用；3：已过期 ,
 } 
 */
export function getTaskList (data) {
    return request({
        url: '/taskinfo/queryPageList',
        method: 'post',
        data
    })
}

/**
 * 新增任务
 * @param  data 
 * @returns 
 * data:{
    beginTime (string, optional): 拍摄开始时间 ,
    cashState (integer, optional): 提现状态：0：未提现；1：提现中；2：已提现 ,
    city (string, optional): 市 ,
    coordinateList (Array[CoordinateInfo], optional): 具体坐标列表 ,
    createUserCode (string, optional): 创建人用户编号 ,
    district (string, optional): 区 ,
    endTime (string, optional): 拍摄结束时间 ,
    finishTarget (integer, optional): 提前完成指标，百分比 ,
    itemAgainMoney (integer, optional): 二次采集奖励，百分比 ,
    itemCount (integer, optional): 任务项数量 ,
    itemPrice (integer, optional): 任务项单价，厘 ,
    mediaTypeIdList (Array[integer], optional): 媒介类型编号列表 ,
    name (string, optional): 任务名称 ,
    province (string, optional): 省 ,
    requirement (string, optional): 任务要求 ,
    taskDays (integer, optional): 任务天数 ,
    timeoutCutMoney (integer, optional): 超时扣款，百分比 ,
    type (integer, optional): 任务类型：1：抢单；2：指定
 } 
    CoordinateInfo {
        latitude (string, optional),
        longitude (string, optional)
    }
 */
export function taskAdd (data) {
    return request({
        url: '/taskinfo/add',
        method: 'post',
        data
    })
}

/**
 * 新增任务
 * @param  data 
 * @returns 
 * data:{
    taskId (string, optional): 任务编号 ,
    beginTime (string, optional): 拍摄开始时间 ,
    cashState (integer, optional): 提现状态：0：未提现；1：提现中；2：已提现 ,
    city (string, optional): 市 ,
    coordinateList (Array[CoordinateInfo], optional): 具体坐标列表 ,
    createUserCode (string, optional): 创建人用户编号 ,
    district (string, optional): 区 ,
    endTime (string, optional): 拍摄结束时间 ,
    finishTarget (integer, optional): 提前完成指标，百分比 ,
    itemAgainMoney (integer, optional): 二次采集奖励，百分比 ,
    itemCount (integer, optional): 任务项数量 ,
    itemPrice (integer, optional): 任务项单价，厘 ,
    mediaTypeIdList (Array[integer], optional): 媒介类型编号列表 ,
    name (string, optional): 任务名称 ,
    province (string, optional): 省 ,
    requirement (string, optional): 任务要求 ,
    taskDays (integer, optional): 任务天数 ,
    timeoutCutMoney (integer, optional): 超时扣款，百分比 ,
    type (integer, optional): 任务类型：1：抢单；2：指定
 } 
    CoordinateInfo {
        latitude (string, optional),
        longitude (string, optional)
    }
 */
export function taskUpdata (data) {
    return request({
        url: '/taskinfo/update',
        method: 'post',
        data
    })
}

/**
 * 查询任务详情
 * @param  data 
 * @returns 
 * data:{
    taskId (string): 任务编号 ,
 } 
 */
export function queryTaskInfo (data) {
    return request({
        url: '/taskinfo/query',
        method: 'get',
        params: data
    })
}

/**
 * 查询任务审核详情
 * @param  data 
 * @returns 
 * data:{
    taskId (string): 任务编号 ,
 } 
 */
export function queryTaskReviewInfo (data) {
    return request({
        url: '/taskinfo/queryTaskPoint',
        method: 'get',
        params: data
    })
}

/**
 * 查询对应点位下的采集数据
 * @param  data 
 * @returns 
 * data:{
    pointId (string): 点位编号 ,
 } 
 */
export function queryDatapointCollectionInfo (data) {
    return request({
        url: '/datacollection/queryByPoint',
        method: 'post',
        data
    })
}

/**
 * 审读任务
 * @param  data 
 * @returns 
 * data:{
    days (integer, optional): 宽限天数 ,
    passIds (Array[string], optional): 通过的采集数据编号列表 ,
    rejectIds (Array[RejectCollectionDataForm], optional): 被驳回的采集数据编号列表 ,
    taskId (string, optional): 任务编号 ,
    type (integer, optional): 审核类型：1：同意；2：驳回
 } 
    RejectCollectionDataForm {
        flowId (string, optional): 采集数据编号 ,
        reason (string, optional): 驳回原因
    }
 */
export function examineTask (data) {
    return request({
        url: '/taskinfo/examineTask',
        method: 'post',
        data
    })
}

/**
 * 删除任务
 * @param  data 
 * @returns 
 * data:{
    taskId (string, optional): 任务编号 ,
 */
export function deleteTask (data) {
    return request({
        url: '/taskinfo/delete',
        method: 'get',
        params: data
    })
}

/**
 * 审读任务中提交上来的图片
 * @param  data 
 * @returns 
 * data:{
    passIds (Array[string], optional): 通过的采集数据编号列表 ,
    rejectIds (Array[RejectCollectionData], optional): 被驳回的采集数据编号列表 ,
 }
    RejectCollectionData {
        flowId (string, optional),
        reason (string, optional)
    }
 */
export function reviewData (data) {
    return request({
        url: '/datacollection/reviewData',
        method: 'post',
        data
    })
}

/**
 * 更新已审读的图片
 * @param  data 
 * @returns 
 * data:{
    flowId (string): 采集编号 ,
    reviewComments (string, optional): 审核意见 ,
    reviewResults (integer, optional): 审核结果: 1通过 2拒绝 ,
 }
 */
export function datacollectionUpdate (data) {
    return request({
        url: '/datacollection/update',
        method: 'post',
        data
    })
}

/**
 * 更新广告主审读信息
 * @param  data 
 * @returns 
 * data:{
    flowId (string): 采集编号 ,
    remark (string, optional): 审核备注 ,
    reviewResults (integer, optional): 审核结果: 1通过 2拒绝 3 未审核
 }
 */
export function dataadvertisersUpdate (data) {
    return request({
        url: '/dataadvertisers/update',
        method: 'post',
        data
    })
}

/**
 * 区代账号强制提交任务
 * @param  data 
 * @returns 
 * data:{
    taskId (string, optional): 任务编号
 }
 */
export function regionalAdd (data) {
    return request({
        url: '/datacollection/regionalAdd',
        method: 'post',
        data
    })
}

/**
 * 区代账号审读app提交的采集数据
 * @param  data 
 * @returns 
 * data:{
    passIds (Array[PassCollectionData], optional): 通过的采集数据编号列表 ,
    rejectIds (Array[RejectCollectionData], optional): 被驳回的采集数据编号列表 ,
    taskId (string, optional): 任务编号
 }
    PassCollectionData {
        flowId (string, optional),
        pointName (string, optional),
        mediaName (string, optional),
        recordId (integer, optional),
        industryName (string, optional),
        advertisersName (string, optional),
        advertisersPhone (string, optional),
        mediaOwnerName (string, optional)
    }
    RejectCollectionData {
        flowId (string, optional),
        reason (string, optional)
    }
 */
export function esaycollectionReviewData (data) {
    return request({
        url: '/dataesaycollection/reviewData',
        method: 'post',
        data
    })
}

/**
 * 更新app已审读的图片
 * @param  data 
 * @returns 
 * data:{
    flowId (string): 采集编号 ,
    reviewComments (string, optional): 审核意见 ,
    reviewResults (integer, optional): 审核结果: 1通过 2拒绝 ,
    advertisersName (string, optional): 广告主名称 ,
    advertisersPhone (string, optional): 广告主电话 ,
    district (string, optional): 区 ,
    flowId (string): 采集编号 ,
    industryName (string, optional): 行业名称 ,
    mediaName (string, optional): 媒介名称 ,
    mediaOwnerName (string, optional): 媒介主名称 ,
    pointName (string, optional): 点位名称 ,
    province (string, optional): 省 ,
 }
 */
export function esaycollectionUpdate (data) {
    return request({
        url: '/dataesaycollection/update',
        method: 'post',
        data
    })
}

/**
 * 查询已有的广告主
 * @param  data 
 * @returns 
 * data:{
   advertisersName (string, optional): 广告主名称 ,
 }
 */
export function getAdvertiserList (data) {
    return request({
        url: '/dataadvertisersrecord/queryList',
        method: 'post',
        data
    })
}

/**
 * 查询行业列表（不分页）
 * @param  data 
 * @returns 
 * data:{
    type (integer, optional): 类型：1 媒介类型枚举值；2 任务审核拒绝枚举值；3 行业查询,
 } 
 */
export function getIndustryNameList (data) {
    return request({
        url: '/systemproperties/queryList',
        method: 'post',
        data
    })
}

/**
 * 采集中任务的审读上报（小程序，边采集边审读）
 * @param  data 
 * @returns 
 * data:{
    passIds (Array[string], optional): 通过的采集数据编号列表 ,
    rejectIds (Array[RejectCollectionData], optional): 被驳回的采集数据编号列表 ,
 }
    RejectCollectionData {
        flowId (string, optional),
        reason (string, optional)
    }
 */
export function reviewDataByReport (data) {
    return request({
        url: '/datacollection/reviewDataByReport',
        method: 'post',
        data
    })
}

/**
 * 采集中任务的审读上报（app，边采集边审读）
 * @param  data 
 * @returns 
 * data:{
    passIds (Array[PassCollectionData], optional): 通过的采集数据编号列表 ,
    rejectIds (Array[RejectCollectionData], optional): 被驳回的采集数据编号列表 ,
    taskId (string, optional): 任务编号
 }
    PassCollectionData {
        flowId (string, optional),
        pointName (string, optional),
        mediaName (string, optional),
        recordId (integer, optional),
        industryName (string, optional),
        advertisersName (string, optional),
        advertisersPhone (string, optional),
        mediaOwnerName (string, optional)
    }
    RejectCollectionData {
        flowId (string, optional),
        reason (string, optional)
    }
 */
export function reviewAppDataByReport (data) {
    return request({
        url: '/dataesaycollection/reviewDataByReport',
        method: 'post',
        data
    })
}